const designPieces = [
	{
		"imgSrc":"/images/arts-don-giovanni.jpg",
		"imgDesc1": "Illustrator: Cristina Byvik",
		"imgDesc2": "Designer: Gloria Orbegozo",
		"imgDesc3": "Design Director: Peter Nguyen",
		"imgClass": "narrow",
		"imgAlt": "Don Giovanni cover"
	},
	{
		"imgSrc":"/images/wkend-fury.jpg",
		"imgDesc1": "Illustrator: Adam Vieyra",
		"imgDesc2": "Designer: Adam Vieyra",
		"imgDesc3": "Design Director: Peter Nguyen",
		"imgClass": "narrow",
		"imgAlt": "Weekend Fury cover"
	},
	{
		"imgSrc":"/images/arts-preview.jpg",
		"imgDesc1": "Illustrator: Cristina Byvik",
		"imgDesc2": "Designer: Gloria Orbegozo",
		"imgDesc3": "Design Director: Peter Nguyen",
		"imgClass": "narrow",
		"imgAlt": "Arts preview cover"
	},
	{
		"imgSrc":"/images/wkend-equalizer.jpg",
		"imgDesc1": "",
		"imgDesc2": "Designer: Jose Antonio Morales",
		"imgDesc3": "Design Director: Peter Nguyen",
		"imgClass": "narrow",
		"imgAlt": "Weekend Equalizer cover"
	},
	{
		"imgSrc":"/images/wkend-mockingjay.jpg",
		"imgDesc1": "Illustrator: Adam Vieyra",
		"imgDesc2": "Designer: Adam Vieyra",
		"imgDesc3": "Design Director: Peter Nguyen",
		"imgClass": "narrow",
		"imgAlt": "Weekend Mockingjay cover"
	},
	{
		"imgSrc":"/images/arts-pagliacci.jpg",
		"imgDesc1": "Illustrator: Cristina Byvik",
		"imgDesc2": "Designer: Gloria Orbegozo",
		"imgDesc3": "Design Director: Peter Nguyen",
		"imgClass": "narrow",
		"imgAlt": "Arts Pagliacci cover"
	},
	{
		"imgSrc":"/images/wkend-moses.jpg",
		"imgDesc1": "Illustrator: Adam Vieyra",
		"imgDesc2": "Designer: Adam Vieyra",
		"imgDesc3": "Design Director: Peter Nguyen",
		"imgClass": "narrow",
		"imgAlt": "Weekend Moses cover"
	},
	{
		"imgSrc":"/images/wkend-boyhood.jpg",
		"imgDesc1": "",
		"imgDesc2": "Designer: Jose Antonio Morales",
		"imgDesc3": "Design Director: Peter Nguyen",
		"imgClass": "narrow",
		"imgAlt": "Weekend Boyhood cover"
	},
	{
		"imgSrc":"/images/wkend-skyfall.jpg",
		"imgDesc1": "",
		"imgDesc2": "Designer: Peter Nguyen",
		"imgDesc3": "Design Director: Peter Nguyen",
		"imgClass": "narrow",
		"imgAlt": "Weekend Skyfall cover"
	},
	{
		"imgSrc":"/images/nd-mardigras.jpg",
		"imgDesc1": "Illustrator: Martin Gee",
		"imgDesc2": "Designer: Peter Nguyen",
		"imgDesc3": "Design Director: Peter Nguyen",
		"imgClass": "narrow",
		"imgAlt": "Mardi Gras cover"
	},
	{
		"imgSrc":"/images/nd-jet.jpg",
		"imgDesc1": "Illustrator: James Flames",
		"imgDesc2": "Designer: Chris Barber",
		"imgDesc3": "Design Director: Peter Nguyen",
		"imgClass": "narrow",
		"imgAlt": "Jet Propulsion cover"
	},
	{
		"imgSrc":"/images/arts-opera-pit.jpg",
		"imgDesc1": "Illustrator: Cristina Byvik",
		"imgDesc2": "Designer: Peter Nguyen",
		"imgDesc3": "Design Director: Peter Nguyen",
		"imgClass": "narrow",
		"imgAlt": "Opera Faust cover"
	},
	{
		"imgSrc":"/images/squeeze-vip.jpg",
		"imgDesc1": "",
		"imgDesc2": "Designer: Peter Nguyen",
		"imgDesc3": "Design Director: Peter Nguyen",
		"imgClass": "wide",
		"imgAlt": "Squeeze VIP spread"
	},
];

export default designPieces;